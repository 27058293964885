export function normalizeNull<T>(obj: null): undefined;
export function normalizeNull<T>(obj: T): T;
export function normalizeNull<T>(obj: T[]): T[];

/**
 * Normalize null to undefined and or normalize each property in an object
 * which is null to undefined
 */
export function normalizeNull<T>(obj: T | T[] | null): T | T[] | undefined {
  if (obj === null) {
    return undefined;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => normalizeNull(item));
  }
  if (typeof obj === 'object') {
    //eslint-disable-next-line guard-for-in
    for (let key in obj) {
      obj[key] = normalizeNull(obj[key]);
    }
  }

  return obj;
}
