import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * @deprecated newer better version will be made soon @HJ:2023-04-16
 * This directive can be used to add a loading indicator to a button or list item element.
 *
 * @example
 * <ion-button *ngFor="let color of colors"
 *              appLoading
 *              style="append"
 *              loadingSlot="end"
 *              [color]="color"
 *              [isLoading]="(isLoading$ | async)"
 *              (click)="isLoading$.next(true)">
 *    Opslaan
 * </ion-button>
 */
@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appLoading]',
})
export class LoadingDirective implements OnInit {

  /**
   * Internal subject used to keep track of the 'is loading' state.
   */
  public _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * This input can be used to change to the loading state. True means this indicator is visible.
   * @param value
   */
  @Input() public set isLoading(value: boolean) {
    this._isLoading$.next(value);
  }

  /**
   * This input can be used to add the loading indicator to a certain ionic slot.
   * This is only useful when using the 'append' style.
   */
  @Input() public loadingSlot: string = 'start';

  /**
   * This input can be used to determine which type you want to use.
   *
   * append: This style adds the loading indicator before or after the text of
   * the button or list item element.
   *
   * replace: This style replaces the button or list item element text with the
   * loading indicator.
   */
  @Input() public style: 'replace' | 'append' = 'replace';

  /**
   * The type of loading icon.
   */
  @Input() public name: string = 'crescent';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.elementRef
      .nativeElement
      .innerHTML = `
            <ion-spinner slot="${this.loadingSlot}" name="${name}"></ion-spinner>
            ${this.elementRef.nativeElement.innerHTML}
        `;

    this.renderer.addClass(this.elementRef.nativeElement, 'app-loading');
    this.renderer.addClass(this.elementRef.nativeElement, this.style);


    this._isLoading$.subscribe(
      isLoading => {
        this.toggleLoading(isLoading);
      },
    );
  }

  /**
   * Toggle the loading state
   * @param isLoading
   */
  private toggleLoading(isLoading: boolean): void {
    if (isLoading) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'app-loading-active',
      );
      return;
    }

    this.renderer.removeClass(
      this.elementRef.nativeElement,
      'app-loading-active',
    );
  }
}
