import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ttConvertToColor',
})
export class TtConvertToColorPipe implements PipeTransform {

  public transform(value: string): string {
    if (value.substring(0,2) === '--') {
      return `var(${value})`;
    }
    return value;
  }

}
